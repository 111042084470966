import { FC, useMemo } from "react";
import {
  Box,
  Button,
  Collapse,
  Flex,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { Product } from "@/hooks/products/utils";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { Link } from "@chakra-ui/next-js";
import {
  Dimensions as DimensionsType,
  PackageTypeEnum,
} from "@/documents/__generated__/globalTypes.codegen";
import pluralize from "pluralize";
import { DeepPartial } from "@apollo/client/utilities";

type DimensionsProps = {
  dimensions: DeepPartial<DimensionsType>;
};

const Dimensions: FC<DimensionsProps> = ({ dimensions }) => {
  const description = useMemo(() => {
    const result = [];

    if (dimensions?.length) {
      result.push(`L ${dimensions.length}"`);
    }

    if (dimensions?.width) {
      result.push(`W ${dimensions.width}"`);
    }

    if (dimensions?.height) {
      result.push(`H ${dimensions.height}"`);
    }

    return result.join(" x ");
  }, [dimensions]);

  return <Td>{description}</Td>;
};

type ProductDescriptionProps = {
  product: Product;
  onProposition65Click: () => void;
};

const ProductDescription: FC<ProductDescriptionProps> = ({
  product,
  onProposition65Click,
}) => {
  const { isOpen, onToggle } = useDisclosure();
  const { hasCount, hasWeight, hasDimensions, hasQuantityDescription } =
    useMemo(() => {
      return {
        hasCount: product.variants.some((variant) => variant.itemsCount),
        hasDimensions: product.variants.some((variant) => variant.dimensions),
        hasWeight: product.variants.some((variant) => variant.weight),
        hasQuantityDescription: product.variants.some(
          (variant) => variant.quantityDescription,
        ),
      };
    }, [product]);
  const hasTable = hasCount || hasWeight || hasDimensions;

  return (
    <Flex direction="column" gap={6}>
      <Box>
        {product.description && <Text>Description: {product.description}</Text>}
        <Text>
          SKU: <Text as="b">{product.sku}</Text>
        </Text>
        {product.upcs?.length ? (
          <Text>
            UPC: <Text as="b">{product.upcs.join(", ")}</Text>
          </Text>
        ) : null}
      </Box>
      {hasTable && (
        <TableContainer whiteSpace="wrap">
          <Table size="sm" variant="simple">
            <TableCaption>
              Product information or packaging displayed may not be current or
              complete. *Actual weight may vary based on seasonality and other
              factors.
            </TableCaption>
            <Thead>
              <Tr>
                {product.variants.map((variant) => (
                  <Th key={variant.id}>{variant.packageTypeTitle}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {hasCount && (
                <Tr>
                  {product.variants.map((variant) => (
                    <Td key={variant.id}>
                      {variant.itemsCount}{" "}
                      {variant.packageType === PackageTypeEnum.Case
                        ? pluralize("Each", variant.itemsCount)
                        : pluralize("Item", variant.itemsCount)}
                    </Td>
                  ))}
                </Tr>
              )}
              {hasDimensions && (
                <Tr>
                  {product.variants.map(({ dimensions, id }) => {
                    if (!dimensions) {
                      return null;
                    }

                    return <Dimensions key={id} dimensions={dimensions} />;
                  })}
                </Tr>
              )}
              {hasWeight && (
                <Tr>
                  {product.variants.map((variant) => {
                    if (variant.weight == null) {
                      return null;
                    }

                    return <Td key={variant.id}>{variant.weight} lbs</Td>;
                  })}
                </Tr>
              )}
              {hasQuantityDescription && (
                <Tr>
                  {product.variants.map((variant) => {
                    return (
                      <Td key={variant.id}>{variant.quantityDescription}</Td>
                    );
                  })}
                </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      )}
      <Box>
        <Button
          size="sm"
          rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
          variant="link"
          onClick={() => {
            onToggle();
            onProposition65Click();
          }}
        >
          Proposition 65 warning
        </Button>
        <Collapse in={isOpen} animate={false}>
          <Text fontSize="xs">
            Warning: This product can expose you to chemicals including arsenic,
            which is known to the State of California to cause cancer. For more
            information, go to{" "}
            <Link href="https://www.P65Warnings.ca.gov" target="_blank">
              www.P65Warnings.ca.gov
            </Link>
          </Text>
        </Collapse>
      </Box>
    </Flex>
  );
};

export default ProductDescription;
