import {
  Badge,
  Card,
  CardBody,
  Flex,
  Tag,
  Text,
  HStack,
} from "@chakra-ui/react";
import { BiSolidBadgeDollar } from "react-icons/bi";
import QuantitySelect, {
  Option,
  VariantOption,
} from "@/components/ProductList/ProductDetails/QuantitySelect";
import { getDollarsDisplayPrice } from "@/utils/price";
import clsx from "clsx";
import { FC } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { Inputs } from "@/components/ProductList/ProductDetails";
import pluralize from "pluralize";
import { PackageTypeEnum } from "@/documents/__generated__/globalTypes.codegen";

type ProductVariantsProps = {
  loading: boolean;
  onFocus: (
    option: VariantOption & {
      options: Option[];
    },
  ) => void;
  onBlur: (
    option: VariantOption & {
      options: Option[];
    },
  ) => void;
  available: boolean;
};

const ProductVariants: FC<ProductVariantsProps> = ({
  loading,
  onFocus,
  onBlur,
  available,
}) => {
  const { control, register } = useFormContext<Inputs>();
  const { fields } = useFieldArray({
    control,
    name: "variants",
  });

  return fields.map((variant, index) => {
    return (
      <Flex direction="column" gap={2} key={index}>
        {variant.volumeDiscountedPriceLevel?.quantity && (
          <Tag colorScheme="blue" gap={2} size="lg" alignItems="center">
            <BiSolidBadgeDollar />
            <Text fontSize="sm" align="center" as="b">
              Save when you buy {variant.volumeDiscountedPriceLevel?.quantity}{" "}
              or more
            </Text>
          </Tag>
        )}
        <Card variant="outline">
          <CardBody>
            <input
              type="hidden"
              {...register(`variants.${index}.packageType`, {
                value: variant.packageType,
              })}
            />
            <QuantitySelect
              rules={{
                validate: (_, formValues) => {
                  return formValues.variants.some(
                    ({ quantity }) => quantity !== null,
                  );
                },
              }}
              onFocus={() => {
                onFocus(variant);
              }}
              onBlur={() => {
                onBlur(variant);
              }}
              inCart={!!variant.quantity}
              isDisabled={loading || !available}
              label={
                <Flex gap={2} justify="space-between" alignItems="start">
                  <HStack gap={2} alignItems="baseline">
                    <Text as="b">{variant.packageTypeTitle}</Text>
                    {variant.weight && variant.catchweightItem && (
                      <Text as="span" color="gray.500" fontSize="sm">
                        avg {variant.weight} {pluralize("lb", variant.weight)}
                      </Text>
                    )}
                    {variant.packageType === PackageTypeEnum.Case &&
                      variant.itemsCount && (
                        <Text as="span" color="gray.500" fontSize="sm">
                          {variant.itemsCount}{" "}
                          {pluralize("Each", variant.itemsCount)}
                        </Text>
                      )}
                  </HStack>
                  <Flex gap={2} alignItems="center">
                    {variant.basePriceLevel.discountedPrice && (
                      <Text as="b" color="green.500">
                        {getDollarsDisplayPrice(
                          variant.basePriceLevel.discountedPrice,
                        )}
                      </Text>
                    )}
                    <Text
                      as="b"
                      {...(variant.basePriceLevel.discountedPrice && {
                        fontSize: "sm",
                      })}
                      className={clsx({
                        ["line-through"]:
                          variant.basePriceLevel.discountedPrice,
                      })}
                    >
                      {getDollarsDisplayPrice(variant.basePriceLevel.price)}
                    </Text>
                    {variant.basePriceLevel.context && (
                      <Badge
                        {...(variant.basePriceLevel.discountedPrice && {
                          colorScheme: "green",
                          variant: "solid",
                        })}
                      >
                        {getDollarsDisplayPrice(
                          variant.basePriceLevel.context.price,
                        )}
                        /{variant.basePriceLevel.context.uom}
                      </Badge>
                    )}
                  </Flex>
                </Flex>
              }
              variant={variant}
              name={`variants.${index}.quantity`}
              control={control}
            />
          </CardBody>
        </Card>
      </Flex>
    );
  });
};

export default ProductVariants;
