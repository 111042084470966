import {
  useMutation,
  MutationHookOptions,
  NoInfer,
  MutationTuple,
  ApolloCache,
  DefaultContext,
  OperationVariables,
  ApolloError,
  ServerError,
  MaybeMasked,
  FetchResult,
} from "@apollo/client";
import type { DocumentNode } from "graphql/index";
import type { TypedDocumentNode } from "@graphql-typed-document-node/core";
import { ToastId, useToast, UseToastOptions } from "@chakra-ui/react";
import { useCallback, useMemo } from "react";
import { getOperationName } from "@apollo/client/utilities";
import has from "lodash/has";
import isObject from "lodash/isObject";
import isEmpty from "lodash/isEmpty";
import { sessionUserReactiveVar } from "@/app/UserProvider";
import { shoppingSessionReactiveVar } from "@/hooks/session/useShoppingSession";
import { InvalidTokenError } from "@/app/lib/utils";

type MaybeFunction<T, Args extends unknown[] = []> = T | ((...args: Args) => T);

type UseToastPromiseOption = Omit<UseToastOptions, "status">;
export type ToastPromiseOptions<TData> = {
  success: MaybeFunction<UseToastPromiseOption, [TData]>;
  error: MaybeFunction<UseToastPromiseOption, [ApolloError]>;
  loading: UseToastPromiseOption;
  id?: ToastId;
};

const deepFind = (object: object, key: string) => {
  // If the current object directly has the key, return its value
  if (object.hasOwnProperty(key)) {
    return (object as Record<string, unknown>)[key];
  }

  // Iterate over each property in the object
  for (const k in object) {
    if (has(object, k) && isObject(object[k])) {
      // Recursively search in the nested object
      const result: unknown = deepFind(object[k], key);
      if (result !== undefined) {
        return result;
      }
    }
  }

  return undefined; // Key does not exist at unknown level
};

const catchGQLError = async <TData extends object>(data: TData) => {
  const errors = deepFind(data, "errors");

  if (errors && !isEmpty(errors)) {
    const error = new ApolloError({
      graphQLErrors: Array.isArray(errors) ? errors : [errors],
    });
    return Promise.reject(error);
  }

  return data;
};

const useToastedMutation = <
  TData = unknown,
  TVariables = OperationVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<unknown> = ApolloCache<unknown>,
>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  {
    toastPromiseOptions,
    ...options
  }: MutationHookOptions<
    NoInfer<TData>,
    NoInfer<TVariables>,
    TContext,
    TCache
  > & {
    toastPromiseOptions?: ToastPromiseOptions<FetchResult<MaybeMasked<TData>>>;
  } = {},
): MutationTuple<TData, TVariables, TContext, TCache> => {
  const toast = useToast({
    position: "bottom-left",
    ...(toastPromiseOptions?.id && { id: toastPromiseOptions.id }),
  });

  const [mutate, ...rest] = useMutation<TData, TVariables, TContext, TCache>(
    mutation,
    options,
  );

  toastPromiseOptions = useMemo(
    () =>
      toastPromiseOptions || {
        success: {
          title: "Success",
          description: `Operation ${getOperationName(mutation)} completed successfully`,
        },
        error: (e: Error) => {
          return {
            title: "Error",
            description: e.message,
          };
        },
        loading: {
          title: "Loading",
          description: `Operation ${getOperationName(mutation)}`,
        },
      },
    [mutation, toastPromiseOptions],
  );

  const mutateWithToast = useCallback(
    (...args: Parameters<typeof mutate>) => {
      const [options, ...rest] = args;
      const result = mutate(
        {
          onError: (e) => {
            // If the error is due to an expired session, reset the cart and session user
            if ((e.networkError as ServerError)?.statusCode === 401) {
              shoppingSessionReactiveVar({
                data: null,
                loading: false,
              });
              sessionUserReactiveVar({
                error: InvalidTokenError,
                loading: false,
              });
            }
          },
          ...options,
        },
        ...rest,
      ).then(catchGQLError);
      if (toastPromiseOptions.id) {
        if (!toast.isActive(toastPromiseOptions.id)) {
          toast.promise<FetchResult<MaybeMasked<TData>>, ApolloError>(
            result,
            toastPromiseOptions,
          );
        }
      } else {
        toast.promise<FetchResult<MaybeMasked<TData>>, ApolloError>(
          result,
          toastPromiseOptions,
        );
      }

      return result;
    },
    [mutate, toast, toastPromiseOptions],
  );

  return [mutateWithToast, ...rest];
};

export default useToastedMutation;
